import React from 'react'
import './pricing.css'

// My Comps
import PricingTable from './PricingTable';

function Pricing() {
  return (
    <PricingTable/>
  )
}

export default Pricing