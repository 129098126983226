import React from 'react'

function Introduction() {
    return (
        <>
            <h1>Tomier Plus Quality Mobile Detailing</h1>
            <p>We are a local family-owned business. Here we are for all of your auto detailing and paint correction needs. We offer packages that will meet your needs. Contact us for a free estimate! We'll be happy to help!</p>
        </>
    )
}

export default Introduction